.popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);
    border-radius: 10px;
    border: 1px solid #cccccc;
    padding: 10px;
    transform: translate(0%, -50%);
    min-width: 250px;
}
.popup:after {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 0%;
    top: 50%;
    margin-left: -20px;
    margin-top: -10px;
    transform: translate(0%, 0%) rotate(90deg);
}
