.mainContainer {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}

.boarder {
  border: 1px solid black;
  border-collapse: collapse;
}

.center {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}